import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const SubscribeService = {
  subscribeUser() {
    return cancellableRequest({
      url: `${apiUrlV1}/users/subscribe_to_notifications`,
      method: 'patch',
    });
  },
  getSubscribeInfo() {
    return cancellableRequest({
      url: `${apiUrlV1}/users/notification_subscribe_data`,
      method: 'get',
    });
  },
  updateSubscriptionType(id, data) {
    return cancellableRequest({
      url: `${apiUrlV1}/vacancy_subscriptions/${id}/update_subscription_type`,
      method: 'patch',
      data,
    });
  },
};
